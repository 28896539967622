<template>
  <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
  >
    <div>
      <b-card>
        <b-row>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Rut Empresa
            </h5>
            <b-form-group
                :state="rut_empresa_state"
                invalid-feedback="Rut es requerido"

            >
              <b-form-input
                  id="rut_empresa"
                  v-model="dataEmpresa.rut_empresa"
                  :state="rut_empresa_state"
                  placeholder="Ingrese RUT 123456789-0"
                  required
                  readonly
                  type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Nombre Fantasía
            </h5>
            <b-form-group
                :state="nombre_fantasia_state"
                invalid-feedback="Nombre Fantasía es requerido"
            >
              <b-form-input
                  id="nombre_fantasia"
                  v-model="dataEmpresa.nombre_fantasia"
                  :state="nombre_fantasia_state"
                  placeholder="Nombre Fantasia"
                  required
                  type="text"
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Razón Social
            </h5>
            <b-form-group
                :state="razon_social_empresa_state"
                invalid-feedback="Nombre Fantasía es requerido"
            >
              <b-form-input
                  id="razon_social_empresa"
                  v-model="dataEmpresa.razon_social_empresa"
                  :state="razon_social_empresa_state"
                  placeholder="Ingrese Razon Social identificador de la empresa"
                  required
                  type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Holding
            </h5>
            <autocomplete-infinity
                url="entidades/holding"
                :selectedValue="dataEmpresa.holding"
                placeholder="Seleccione Holding"
                label="nombre_holding"
                items-per-page="10"

            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Descripción Empresa
            </h5>
            <b-form-group
                :state="descripcion_empresa_state"
                invalid-feedback="Es requerido"
            >
              <b-form-textarea
                  id="descripcion_empresa"
                  v-model="dataEmpresa.descripcion_empresa"
                  :state="descripcion_empresa_state"
                  placeholder="Ingrese observaciones o referencias de la empresa a registrar.Estas observaciones pueden servir para recordar de que empresa se trata en caso que no se acuerde bien."
                  required
                  rows="3"
                  type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Giro Empresa
            </h5>
            <b-form-input
                id="h-first-name"
                v-model="dataEmpresa.giro_empresa"
                placeholder="Giro"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Comuna
            </h5>
            <autocomplete-infinity

                :selectedValue="dataEmpresa.comuna"
                url="entidades/comuna"
                placeholder="Seleccione Comuna"
                label="nombre_comuna"
                items-per-page="10"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Teléfono Temporal
            </h5>
            <b-form-input
                id="h-first-name"
                v-model="dataEmpresa.telefono_temporal"
                placeholder="Teléfono temporal"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Dirección Factura
            </h5>
            <b-form-input
                id="h-first-name"
                v-model="dataEmpresa.direccion_factura"
                placeholder="Dirección Factura"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Comuna Factura
            </h5>
            <autocomplete-infinity
                :selectedValue="dataEmpresa.comuna"
                url="entidades/comuna"
                placeholder="Seleccione Comuna"
                label="nombre_comuna"
                items-per-page="10"

            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Dirección Despacho
            </h5>
            <b-form-input
                v-model="dataEmpresa.direccion_despacho"
                placeholder="Dirección despacho"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Email para SII
            </h5>
            <b-form-input

                v-model="dataEmpresa.mail_sii"
                placeholder="Email para SII"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Nombre Contacto Cobranza
            </h5>
            <b-form-input

                v-model="dataEmpresa.nombre_contacto_cobranza"
                placeholder="Nombre contacto cobranza"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Email para SII
            </h5>
            <b-form-input

                v-model="dataEmpresa.mail_contacto_cobranza"
                placeholder="Email contacto cobranza"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Teléfono Contacto Cobranza
            </h5>
            <b-form-input

                v-model="dataEmpresa.telefono_contacto_cobranza"
                placeholder="Teléfono contacto cobranza"
            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Ejecutivo al que pertenece
            </h5>
            <autocomplete-infinity

                :selectedValue="dataEmpresa.ejecutivo"
                url="entidades/ejecutivo"
                placeholder="Seleccione Ejecutivo"
                label="fullname"
                items-per-page="10"

            />
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Observaciones glosa facturación
            </h5>
            <b-form-group
                :state="observaciones_glosa_facturacion_state"
                invalid-feedback="Es requerido"
            >
              <b-form-textarea

                  v-model="dataEmpresa.observaciones_glosa_facturacion"
                  :state="observaciones_glosa_facturacion_state"
                  placeholder="Ingrese observaciones o referencias de la empresa a registrar.Estas observaciones pueden servir para recordar de que empresa se trata en caso que no se acuerde bien."
                  required
                  rows="3"
                  type="text"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <h5 class="font-weight-bold">
              Opciones
            </h5>
            <div class="demo-inline-spacing">

              <b-form-checkbox
                  v-model="dataEmpresa.requiere_orden_compra"
                  value="1"
                  plain
                  class="custom-control-primary"
              >
                Requiere Orden de Compra
              </b-form-checkbox>
              <b-form-checkbox
                  v-model="dataEmpresa.requiere_hess"
                  value="1"
                  plain
                  class="custom-control-primary"
              >
                Requiere Hess
              </b-form-checkbox>
              <b-form-checkbox
                  v-model="dataEmpresa.requiere_numero_contrato"
                  value="1"
                  plain
                  class="custom-control-primary"
              >
                Requiere número contrato
              </b-form-checkbox>
              <b-form-checkbox
                  v-model="dataEmpresa.requiere_ota"
                  value="1"
                  plain
                  class="custom-control-primary"
              >
                Requiere OTA
              </b-form-checkbox>
            </div>
          </b-col>
          <!-- Enviar y Limpiar -->
          <b-col offset-md="6">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="mr-1"
                @click="atras"
            >
              Cancelar
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="outline-primary"
                @click="handleOk"
            >
              Aceptaar
            </b-button>
          </b-col>
        </b-row>

      </b-card>
    </div>
  </form>
</template>

<script>
import {
  BFormSelect,
  BFormCheckbox,
  BButton,
  BFormInput,
  VBModal,
  BLink,
  BFormFile,
  BPagination,
  BSpinner,
  VBTooltip,
  BFormTextarea,
  BCardText
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import AutocompleteInfinity from '@/components/AutocompleteInfinity.vue'
export default {
  name: "EditarEmpresa",
  components: {
    BFormCheckbox,
    BButton,
    BFormInput,
    flatPickr,
    BLink,
    BFormFile,
    BPagination,
    BSpinner,
    BCardText,
    BFormTextarea,
    AutocompleteInfinity
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {

      rut_empresa_state: null,
      nombre_fantasia_state: null,
      razon_social_empresa_state: null,
      descripcion_empresa_state: null,
      observaciones_glosa_facturacion_state: null,
      transProps: {
        // Transition name
        name: "flip-list"
      }
    };
  },
  mounted() {
    this.obtenerInformacionEmpresa(this.$route.params.id);
  },
  computed: {
    loading() {
      return this.$store.state.empresa.loading;
    },
    dataEmpresa() {
      console.log(this.$store.state.empresa.detalleCompletoEmpresa.holding)
      return this.$store.state.empresa.detalleCompletoEmpresa;
    },
    errorResponseEmpresa() {
      alert(this.$store.state.empresa.errorResponse);
      return this.$store.state.empresa.errorResponse;
    },
    mensajeRespuestaAccion() {
      console.log("mensajeRespuestaAccion", this.$store.state.empresa.mensajeRespuesta);
      this.mensaje = this.$store.state.empresa.mensajeRespuesta;
      console.log("MEnsaje", this.mensaje);
      this.$bvToast.toast({
        title: this.mensaje.titulo,
        variant: "success",
        solid: true,
        message: this.mensaje.mensaje
      });
    }


  },
  watch: {},
  methods: {
    enviarDatosEmpresa() {
      if (!this.validarRUt(this.dataEmpresa.rut_empresa)) alert("malito");
      this.$store.dispatch("empresa/agregarEditarEmpresa", this.dataEmpresa);
      this.mensajeRespuestaAccion();
    },
    obtenerInformacionEmpresa(id_empresa){
      this.$store.dispatch('empresa/getDetalleCompletoEmpresa', {'id_empresa':id_empresa})
    },
    atras() {
      window.history.back();
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.rut_empresa_state = valid;
      this.nombre_fantasia_state = valid;
      this.razon_social_empresa_state = valid;
      this.descripcion_empresa_state = valid;
      this.observaciones_glosa_facturacion_state = valid;
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
    }

  }
};

</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.demo-inline-spacing.curso .custom-radio {
  margin-top: 0.5rem !important;
}

.btn-clear {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0px 3px 3px 0px !important;
  color: #6e6b7b;
}

.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: 'Browse';
}
</style>
